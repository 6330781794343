import React, { Fragment } from "react"
import Layout from "../components/layout"
// import RequestDesignerInvite from "../components/RequestDesignerInvite/RequestDesignerInvite"

const DesignerRequestWithLayout = () => {
  return (
    <Fragment>
      {
        // <RequestDesignerInvite />
      }
    </Fragment>
  )
}

export default () => (
  <Layout>
    <DesignerRequestWithLayout />
  </Layout>
)
